(function (angular) {
    angular.module('mobilezuz').directive('spTooltip', [
        '$compile',
        'Util',
        '$window',
        function ($compile, util, $window) {
            return {
                restrict: 'A',
                scope: {
                    content: '@?spTooltip',
                },
                link: function ($scope, $element) {
                    function createTooltip($scope, $element) {
                        if (!$scope.content) return;
                        document.body.insertAdjacentHTML(
                            'beforeend',
                            "<div class='product-tag-tooltip-wrapper'>" +
                                "<div class='product-tag-tooltip-content'>" +
                                $scope.content +
                                '</div>' +
                                "<div class='tooltip-arrow'>&nbsp;</div>" +
                                '</div>'
                        );
                        var tooltipWrapperElement = document.querySelector('.product-tag-tooltip-wrapper');
                        if (!tooltipWrapperElement) return;
                        var removeWrapper = function () {
                            if (tooltipWrapperElement) {
                                tooltipWrapperElement.remove();
                            }
                        };
                        var iconElement = $element[0].querySelector('.product-main-tag-icon');
                        var tooltipArrowElement = tooltipWrapperElement.querySelector('.tooltip-arrow');
                        if (!tooltipArrowElement || !iconElement) {
                            removeWrapper();
                            return;
                        }
                        var elementPosition = iconElement.getBoundingClientRect();
                        if (!elementPosition) {
                            removeWrapper();
                            return;
                        }
                        updateTooltipPosition(elementPosition, tooltipWrapperElement, tooltipArrowElement, iconElement);
                        var scrollHandler = function () {
                            updateTooltipPosition(elementPosition, tooltipWrapperElement, tooltipArrowElement, iconElement);
                        };
                        document.addEventListener('scroll', scrollHandler, true);
                        tooltipWrapperElement.addEventListener('click', function () {
                            removeWrapper();
                            document.removeEventListener('scroll', scrollHandler, true);
                        });
                    }

                    function updateTooltipPosition(elementPosition, tooltipWrapperElement, tooltipArrowElement, iconElement) {
                        var tooltipContentElement = tooltipWrapperElement.querySelector('.product-tag-tooltip-content');
                        if (!tooltipContentElement) {
                            if (tooltipWrapperElement) {
                                tooltipWrapperElement.remove();
                            }
                            return;
                        }
                        if (tooltipContentElement.offsetWidth < 90) {
                            tooltipContentElement.style.left =
                                elementPosition.left - tooltipContentElement.offsetWidth / 2 + iconElement.offsetWidth / 2 + 'px';
                            tooltipContentElement.style.right = 'auto';
                        }
                        var positionTop = elementPosition.top - tooltipContentElement.offsetHeight - tooltipArrowElement.offsetHeight;
                        tooltipContentElement.style.top = (positionTop - 10 < 0 ? 10 : positionTop) + 'px';
                        tooltipArrowElement.style.left = elementPosition.left + tooltipArrowElement.offsetWidth / 2 + 'px';
                        tooltipArrowElement.style.top = elementPosition.top - tooltipArrowElement.offsetHeight + 'px';
                    }

                    function _bind() {
                        $element.on('click', function () {
                            createTooltip($scope, $element);
                        });
                    }

                    util.currentScopeListener($scope, $scope.$watch('content', _bind));
                },
            };
        },
    ]);
})(angular);
