(function (angular) {
	angular.module('mobilezuz')
		.service('Util', [
			'$q', '$timeout', '$injector', '$location', '$interpolate', '$state', '$rootScope', '$sce', '$window',
			'$filter', 'mDesign', 'Tree', 'Retailer', 'spUnitsConfig', 'ExternalCheckout', 'DeepLinkHandler', 'User',
			'Config', 'Api', 'SpDialogUrlManager', 'CART_LINE_TYPES', 'ORDER_STATUS_STAGES', 'SP_SERVICES', 'DataLayer',
			'CATEGORIES_TYPES', 'GOOGLE_ADDRESS_TYPES','TAG_DATE_CONFIGURATION_TYPE', 'CHARGE_SPECIALS_CALCULATION_TIME', 'DELIVERY_TIMES_TYPES','ORDER_STATUSES',
			'ORDER_UPDATE_POLICY', 'LOYALTY_CLUB_DRIVERS', 'SP_HOME_PAGE_COMPONENT_TYPES',
			function ($q, $timeout, $injector, $location, $interpolate, $state, $rootScope, $sce, $window,
					  $filter, mDesign, tree, retailer, spUnitsConfig, ExternalCheckout, DeepLinkHandler, User,
					  Config, api, SpDialogUrlManager, CART_LINE_TYPES, ORDER_STATUS_STAGES, SP_SERVICES, DataLayer,
					  CATEGORIES_TYPES, GOOGLE_ADDRESS_TYPES, TAG_DATE_CONFIGURATION_TYPE, CHARGE_SPECIALS_CALCULATION_TIME, DELIVERY_TIMES_TYPES, ORDER_STATUSES,
						ORDER_UPDATE_POLICY, LOYALTY_CLUB_DRIVERS, SP_HOME_PAGE_COMPONENT_TYPES
			) {
				var self = this,
					cart,
					orders,
					_previousMetaTag = {},
					_currencyFilter = $filter('currency'),
					_translateFilter = $filter('translate'),
					_nameFilter = $filter('name'),
					dateFilter = $filter('date'),

					/** @type {boolean} */
					_isRetailerPremiumReplacementsEnabled = null;

				self.getProductUnit = getProductUnit;
				self.destroyListeners = destroyListeners;
				self.getQuantityInterval = getQuantityInterval;
				self.getCategory = getCategory;
				self.clearAllStorage = clearAllStorage;
				self.clearLocalStorage = clearLocalStorage;
				self.getActiveLines = getActiveLines;
				self.isWeightQuantity = isWeightQuantity;
				self.isUnitsWeighable = isUnitsWeighable;
				self.productSoldBy = productSoldBy;
				self.trustSrc = trustSrc;
				self.validateMinimumCost = validateMinimumCost;
				self.isCartLineAdded = isCartLineAdded;
				self.isCartLineWithSale = isCartLineWithSale;
				self.getDateTimeFormat = getDateTimeFormat;
				self.getDateFormat = getDateFormat;
				self.getTimeFormat = getTimeFormat;
				self.validateLoyaltyExpirationDate = validateLoyaltyExpirationDate;
				self.isIrrelevantSale = isIrrelevantSale;
				self.filterProductSpecials = filterProductSpecials;
				self.productHasClubSpecial = productHasClubSpecial;
				self.getCashbackLabel = getCashbackLabel;
				self.goToProductDialog = goToProductDialog;
				self.goToLoginDialog = goToLoginDialog;
				self.goToUserMigrationDialog = goToUserMigrationDialog;
				self.goToRecipeDialog = goToRecipeDialog;
				self.isOrderInStatuses = isOrderInStatuses;
				self.getField = getField;
				self.firePromotionEvent = firePromotionEvent;
				self.goToBannerHref = goToBannerHref;
				self.getBranchSettings = getBranchSettings;
				self.copyToClipboard = copyToClipboard;
				self.listenForInAppMessage = listenForInAppMessage;
				self.waitForInitDialog = waitForInitDialog;
				self.currentScopeListener = currentScopeListener;
				self.updateMetaByProduct = updateMetaByProduct;
				self.getCategoryActualTypeByCategoryId = getCategoryActualTypeByCategoryId;
				self.getCategoryActualTypeByProductData = getCategoryActualTypeByProductData;
				self.SingleOperationQueue = SingleOperationQueue;
				self.loyaltyClubAutoRenew = loyaltyClubAutoRenew;
				self.syncExpiredLoyaltyUser = syncExpiredLoyaltyUser;
				self.loyaltyClubAutoConnect = loyaltyClubAutoConnect;
				self.goToLoyaltyIFrameDialog = goToLoyaltyIFrameDialog;
				self.showDeliveryNoAvailableSlotsPopup = showDeliveryNoAvailableSlotsPopup;
				self.reload = reload;
				self.isIos = isIos;
				self.showCouponReminderDialog = showCouponReminderDialog;
				self.isProductOutOfStock = isProductOutOfStock;
				self.isNeedToShowOutOfStockLabel = isNeedToShowOutOfStockLabel;
				self.compiledUserAddress = compileUserAddress;
				self.isSingleItemSpecial = isSingleItemSpecial;
				self.isShowOriginalPriceEnabled = isShowOriginalPriceEnabled;
				self.getCountries = getCountries;
				self.getCountryAutocompleteOptions = getCountryAutocompleteOptions;
				self.getAddressFromAutocomplete = getAddressFromAutocomplete;
				self.checkAddressFields = checkAddressFields;
				self.isOrderEditable = isOrderEditable;
				self.getAddressByZipCode = getAddressByZipCode;
				self.checkForDeliveryPickupServiceFee = checkForDeliveryPickupServiceFee;
				self.isEmptyObjectWithDelivery = isEmptyObjectWithDelivery;
				self.getCountryCode = getCountryCode;
				self.convertArrayToObject = convertArrayToObject;
				self.cleanUserAddressObject = cleanUserAddressObject;
				self.isEligibleForReplacementSuggestions = isEligibleForReplacementSuggestions;
				self.isEnableSuggestion = isEnableSuggestion;
				self.isShowOutOfStock = isShowOutOfStock;
				self.isLoyaltyPremiumPackageEnabled = isLoyaltyPremiumPackageEnabled;
				self.getUserCashbackPoints = getUserCashbackPoints;
				self.showBottleDepositDisclaimer = showBottleDepositDisclaimer;
				self.setGlobalCouponRedemptions = setGlobalCouponRedemptions;
				self.checkPopupDisabledUrls = checkPopupDisabledUrls;
				self.setLiveAlert = setLiveAlert;
				self.constructAdrsText1 = constructAdrsText1;
				self.findUserCashbackLoyaltyClub = findUserCashbackLoyaltyClub;
				self.setProductIndexPosition = setProductIndexPosition;
				self.isSellOnDate = isSellOnDate;
				self.roundNumber = roundNumber;
				self.checkIsPremiumEditOrderToggled = checkIsPremiumEditOrderToggled;
				self.calculateLastUpdateOrderDateTime = calculateLastUpdateOrderDateTime;
				self.calculatePostponeUpdateDateTime = calculatePostponeUpdateDateTime;
				self.getDeliveryTypeText = getDeliveryTypeText;
				self.checkCanUpdateTimeSlot = checkCanUpdateTimeSlot;
				self.getTextByLangAndReplaceParams = getTextByLangAndReplaceParams;
				self.openEditOrderDialog = openEditOrderDialog;
				self.checkIsCalculateCheckoutTime = checkIsCalculateCheckoutTime;
				self.doNotRemoveTemporarilyMissingItems = doNotRemoveTemporarilyMissingItems
				self.getNewTimeSlotData = getNewTimeSlotData;
				self.getTranslatedFullDateTime = getTranslatedFullDateTime;
                self.showPaymentErrorDialog = showPaymentErrorDialog;
				self.closeUpdateDialog = closeUpdateDialog;
				self.isInternalUrl = isInternalUrl;
                self.isRetailerPromotionActive = isRetailerPromotionActive;
				self.getRetailerSupportedDeliveryMethod = getRetailerSupportedDeliveryMethod;
				self.extractHomeCarouselName = extractHomeCarouselName;

				_init();

        function _init() {
          Config.waitForInit().then(function () {
            _isRetailerPremiumReplacementsEnabled = Config.retailer.settings.enablePersonalReplacement;
          });
        }

                function isEligibleForReplacementSuggestions(cartLine) {
					var productId = cartLine.product && cartLine.product.id;
					if (!_isRetailerPremiumReplacementsEnabled || !productId) {
						return false;
					}

					return _isProductOutOfStock(cartLine);
				}

				function isEnableSuggestion(item, isProduct) {
					if(!item) return;
					var suggestions = isProduct ? item._suggestions : item.product && item.product._suggestions;
					return suggestions && suggestions.length && suggestions[0].id;
				}

				function isShowOutOfStock(item, isProduct) {
					if(!item) return;
					var product = isProduct ? item : item.product || { branch: {}};
					return (product.branch && product.branch.isOutOfStock)|| product.showOutOfStock ||
						(!isProduct && (item.isProductOutOfStock || item.isNeedToShowOutOfStockLabel));
				}

				function isLoyaltyPremiumPackageEnabled() {
					var LOYALTY_PREMIUM_PACKAGE_ID = 16;
					return Config.retailer.premiumFeaturesEnabled.includes(LOYALTY_PREMIUM_PACKAGE_ID);
				}

				/**
				 * @param {string} href - The URL to navigate to.
				 * @param {string} [mode='_blank'] - The target window/tab where the URL should open. 
				 *                                   Use '_blank' to open the URL in a new tab (default) or '_current' to open it in the current tab.
				 */
				function goToBannerHref(href, mode) {
					if (!href) return;
					mode = mode || '_blank';

					if (href.indexOf('/') === 0) {
						return $location.url(href);
					}
					// If the URL being loaded is a PDF
					if(href.includes('.pdf') && $window.cordova && $window.cordova.InAppBrowser){
						// Open PDFs in system browser (instead of InAppBrowser)
						// using the google docs viewer because this is the only way to view the pdf without downloading it
					   return $window.cordova.InAppBrowser.open('https://docs.google.com/gview?embedded=true&url=' + encodeURIComponent(href), '_system');
					}

                    if (window.cordova && window.cordova.platformId === 'ios') {
                        cordova.InAppBrowser.open(href, '_system');
                    }

					if (mode === '_current') return;	// Using default behaviour
					$window.open(href, '_blank');
				}

				function isSellOnDate(line, deliveryDate){
					// ignore removed line
					if(line.quantity == 0 || line.removed){
						return true;
					}
					var sellDateTags = (line.product && line.product.productTagsData || []).filter(function (tag) {
						return  tag.typeId === 9  && tag.isActive === true; // TODO: move to sp-product-tag
					});
					var checkSellDateValid = true;
					if(sellDateTags.length  === 0){
						return checkSellDateValid;
					}
					if(!deliveryDate){
						deliveryDate = new Date();
					}
					var isThisLineIsUnavailable = null;
					sellDateTags.forEach(function(sellDateTag){
						if(!isThisLineIsUnavailable && sellDateTag.sellDaysType === TAG_DATE_CONFIGURATION_TYPE.DATE_RAGE && sellDateTag.sellDaysUnavailableDateRange && sellDateTag.sellDaysUnavailableDateRange.start && sellDateTag.sellDaysUnavailableDateRange.end){
							var timeStart = new Date(sellDateTag.sellDaysUnavailableDateRange.start).getTime(),
								timeEnd =  new Date(sellDateTag.sellDaysUnavailableDateRange.end).getTime(),
								deliveryTime = deliveryDate.getTime();

							if (deliveryTime >= timeStart && deliveryTime <= timeEnd) {
								checkSellDateValid = false;
								isThisLineIsUnavailable = true;
							}

						}

						else if(!isThisLineIsUnavailable && sellDateTag.sellDaysType === TAG_DATE_CONFIGURATION_TYPE.WEEK_DAYS && sellDateTag.sellDaysAvailableWeekdays && sellDateTag.sellDaysAvailableWeekdays.length > 0){
							var deliveryDay = deliveryDate.getDay();
							var checkDeliveryDay = sellDateTag.sellDaysAvailableWeekdays.find(function (item) {
								return item === deliveryDay;
							})
							if(checkDeliveryDay == null) {
								checkSellDateValid = false;
								isThisLineIsUnavailable = true;
							}
						}

					});
					return checkSellDateValid;

				}

				function isOrderEditable(order) {
					if (!order || !Config || order.isCustomerEditBlocked) {
						return false
					}

					var updateProcessingOrderEnabled = Config.retailer.settings.allowUpdateOrder === 'true' && isOrderInStatuses(order, ORDER_STATUS_STAGES.IN_PROCESS) && (![ORDER_STATUSES.REGISTERED, ORDER_STATUSES.COLLECTED].includes(order.statusId));
					var settings = Config.retailer.settings;
					var updateOrderType = Number(settings.updateOrderType);
					var isUpdateOrderByStatus = !updateOrderType || updateOrderType === ORDER_UPDATE_POLICY.ORDER_STATUS;
					var allowUpdateAfterOrderClosed = settings.allowUpdateAfterOrderClosed === 'true' && isOrderInStatuses(order, ORDER_STATUS_STAGES.READY)
					if (isUpdateOrderByStatus) {
						return (isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED) || updateProcessingOrderEnabled) && order.branchDeliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICK_AND_GO && _addOffsetToNormalizedTime(order.shippingTimeTo) >= new Date();
					}
					return isBeforeTimeSlot(order) || allowUpdateAfterOrderClosed;

				}

				function isBeforeTimeSlot(order){
					if (!order || !Config) {
						return false
					}
					var settings = Config.retailer.settings;
					var maxHourForOrderUpdate = Number(settings.maxHourForOrderUpdate);
					var minutesBeforeTimeSlot = Number(settings.minutesBeforeTimeSlot);
					var now = new Date();
					var shippingTime = new Date(order.shippingTimeFrom)
					var timeBeforeMinutes = new Date(order.shippingTimeFrom).setMinutes(shippingTime.getMinutes() - (minutesBeforeTimeSlot || 0));
					var isBeforeTimeSlotStart =  timeBeforeMinutes.valueOf() > now.valueOf();
					var isBeforeMaxHour = maxHourForOrderUpdate > now.getHours()
					return isBeforeMaxHour || isBeforeTimeSlotStart;
				}

				// function isOrderEditable(order){
				//     return isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED) && order.branchDeliveryTypeId !== DELIVERY_TYPES.PICK_AND_GO && _addOffsetToNormalizedTime(order.shippingTimeTo) >= new Date();
				// }

				//For example order.shippingTimeTo is stored with offset 0. When we run 'new Date(order.shippingTimeTo)' the new date will be shifted by time zone.
				function _addOffsetToNormalizedTime(timeToOffset){
					var currentDate = new Date();
					var timeToOffsetAsDate = new Date(timeToOffset)
					var timeZoneOffset = currentDate.getTimezoneOffset();
					return new Date(timeToOffsetAsDate.getTime() + timeZoneOffset*60000)
				}

				function getProductUnit(product, soldBy) {
					soldBy = self.productSoldBy(product, soldBy);
					if ((soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name) || (!soldBy && !self.isWeightQuantity(product))) return;
					if (!product.unitResolution || product.unitResolution > 0.2 || $rootScope.defaultWeighableUnit.group === 'us') return $rootScope.defaultWeighableUnit;
					//should change to grams in europe, if unit resolution is under 200 gram
					var mass = spUnitsConfig.getGroup().units.mass,
						units = mass ? mass.units : '';
					for (var i = 0; i < units.length; i++) {
						if (units[i].size >= 1000 || units.length - 1 == i) {
							return units[i - 1] || units[0];
						}
					}
				}

				function _getWeighableProductUnits(line) {
					if (!line.product.productDisplayModeId) {
						line.weighableProductUnits = line.weighableProductUnits || Math.round(line.quantity / line.product.weight);
					} else {
						// quantity for unit-weight product
						if (!line.weighableProductUnits) {
							return line.quantity;
						}
					}
					return line.weighableProductUnits;
				}

				function _setSoldBy(line) {
					if (!line.product.productDisplayModeId) {
						return null;
					} else {
						// set soldBy for unit-weight products
						if (!line.weighableProductUnits) {
							return $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
						} else {
							return $rootScope.PRODUCT_DISPLAY.UNIT.name;
						}
					}
				}

				function isWeightQuantity(product) {
					return product.isWeighable && !product.weight;
				}

				function isCartLineAdded(product) {
					return !product.originalTotalPrice && !product.originalPrice && !product.substituteId && product.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT
				}

				function isCartLineWithSale(item) {
					return (item.regularPrice * item.actualQuantity).toFixed(2) === item.totalPrice.toFixed(2)
				}

				function getDateTimeFormat() {
					return $rootScope.isUs ? 'MM/dd/yyyy h:mma' : 'dd/MM/yyyy HH:mm'
				}

				function getDateFormat() {
					return $rootScope.isUs ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
				}

				function getTimeFormat() {
					return $rootScope.isUs ? 'h:mma' : 'HH:mm'
				}

				function isUnitsWeighable(product) {
					return product && product.isWeighable && !!product.weight;
				}

				function productSoldBy(product, soldBy) {
					/*
						Condition for unit/weight toggle mode is on:
						If the product is weighable and has est. weight and weight unit (in case has no weight unit, kg is default)
						Then product can be sold by unit/ weight
					*/
					return self.isUnitsWeighable(product) && soldBy;
				}

				/**
				* Check if special, coupons are expired
				* @param {Line} line
				* @param {boolean} isCalculateAtCheckoutTime
				* @returns
				*/
				function isIrrelevantSale(line, isCalculateAtCheckoutTime) {
					var isLineCanCheckSales =
						line.type === SP_SERVICES.CART_LINE_TYPES.DELIVERY ||
						line.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT;

					/**
					* For checkout time, finalPrice is price at the momment, potential price is price at the past (first checkout)
					* For collectiont ime, finalPrice is price at the momment, potential price is price in the future (delivery date)
					*/
					var isPriceIncreased = isCalculateAtCheckoutTime
						? line.finalPriceForViewPotential < line.finalPriceForView
						: line.finalPriceForView !== line.finalPriceForViewPotential;

					return isLineCanCheckSales && isPriceIncreased;
				}

				function firePromotionEvent(adObject, type) {
					var adId = null;

					if(!isNaN(adObject)) { //== Old type backward
						adId = adObject;
					} else {
						adObject = adObject || {};
						adId = adObject.adId || adObject.id;
					}

					DataLayer.push(DataLayer.EVENTS.SELECT_PROMOTION, {promotion: adObject, data: {type: type}});

					if (!adId) {
						return;
					}

					api.request({
						method: 'GET',
						url: '/v2/retailers/:rid/native-promotion/fire',
						params: {
							adId: adId
						}
					});
				}

				function trustSrc(src) {
					var idVideo, url_array;
					if (src.indexOf('embed') > -1) {
						idVideo = src.split('embed/')[1].split('?')[0];
					} else {
						url_array = src.split('=');
						idVideo = url_array[url_array.length - 1];
					}

					url_array = idVideo.split('/');
					idVideo = url_array[url_array.length - 1];

					var url = 'https://www.youtube.com/embed/' + idVideo + '?&showinfo=0&autoplay=1&rel=0';
					return $sce.trustAsResourceUrl(url);
				}

				function getActiveLines(lines, onlyViewNotActive, allowLoyaltyItems, allowDeliveryItems, fromEditOrder) {
					var toAdd = [],
						notActive = [];

					orders = orders || $injector.get('Orders');

					lines = angular.isArray(lines) ? lines : [lines];
					angular.forEach(lines, function (line) {
						//prevent adding delivery and register loyalty products from old orders or shopping lists
						if ((line.type && line.type != SP_SERVICES.CART_LINE_TYPES.PRODUCT && (line.type != SP_SERVICES.CART_LINE_TYPES.COUPON && !fromEditOrder) &&
							(!allowDeliveryItems || line.type != SP_SERVICES.CART_LINE_TYPES.DELIVERY)) ||
							(line.type == CART_LINE_TYPES.REGISTER_LOYALTY && !allowLoyaltyItems)) {
							return;
						}

						// line.type is undefined if it's a product
						if (!orders.orderInEdit && (!line.product ||
							(!line.isPseudo && !_isProductActive(line.product, line.isCase)) && (!line.type || line.type == CART_LINE_TYPES.PRODUCT) && !doNotRemoveTemporarilyMissingItems(line))) {
							return notActive.push(line);
						}

						//to prevent set values to line (for example in _getWeighableProductUnits)
						if (!!onlyViewNotActive) {
							return;
						}

						toAdd.push({
							isPseudo: line.isPseudo,
							isCase: line.isCase,
							product: angular.copy(line.product),
							comments: line.comments,
							adminComments: line.adminComments,
							quantity: self.isUnitsWeighable(line.product) ? _getWeighableProductUnits(line) : line.quantity,
							soldBy: _setSoldBy(line),
							type: line.type,
							metaData: line.metaData,
							productPropertyValue: line.productPropertyValue
						});
					});

					if (notActive.length) {
						mDesign.dialog({
							focusOnOpen: false,
							clickOutsideToClose: true,
							templateUrl: 'views/templates/not-active-lines-dialog.html',
							controller: ['$scope', function (innerScope) {
								innerScope.notActiveLines = notActive;
								innerScope.cancel = cancel;

								function cancel() {
									mDesign.hide();
								}
							}]
						});
					}

					if (!!onlyViewNotActive) {
						return;
					}

					return toAdd;
				}

				function _isProductActive(product, isCase) {
					return product && product.branch && product.branch.isActive && product.branch.isVisible && product.family && product.family.categoriesPaths &&
						product.family.categoriesPaths.length && (!isCase || product.branch.case && product.branch.case.price);
				}

				function _isProductOutOfStock(cartLine) {
					// isProduct && (!isProductActive || isOutOfStock) && !isPseudo;
					return cartLine.type == SP_SERVICES.CART_LINE_TYPES.PRODUCT && (!_isProductActive(cartLine.product, cartLine.isCase) || !!cartLine.product.branch.isOutOfStock) && !cartLine.isPseudo;
		        }

				function isProductOutOfStock(cartLine) {
					return _isProductOutOfStock(cartLine) && !doNotRemoveTemporarilyMissingItems();
				}

				function isNeedToShowOutOfStockLabel(cartLine) {
					return _isProductOutOfStock(cartLine) && doNotRemoveTemporarilyMissingItems();
				}

				function doNotRemoveTemporarilyMissingItems() {
					return Config.retailer.settings.doNotRemoveTemporarilyMissingItems === 'true';
				}

				/**
				 * Watch for destroy event of given scope and destroys the listeners given
				 * @param {object} scope
				 * @param {Array|function} listeners
				 * @public
				 */
				function destroyListeners(scope, listeners) {
					//var stack = new Error().stack;

					listeners = angular.isFunction(listeners) ? [listeners] : listeners;
					var destroyListener = scope.$on('$destroy', function () {
						destroyListener();
						angular.forEach(listeners, function (listener) {
							angular.isFunction(listener) && listener();
						});
					});
				}

				/**
				 * Returns the product's quantity interval
				 * @param {object} product
				 * @returns {number}
				 * @public
				 */
				function getQuantityInterval(product) {
					return product.isWeighable && !product.weight ? 0.5 : 1;
				}

				/**
				 * Get category
				 * @public
				 *
				 * @param {Number} categoryId
				 *
				 * @returns {Promise}
				 */
				function getCategory(categoryId) {
					return tree.getTree().then(function (tree) {
						for (var i = 0; i < tree.categories.length; i++) {
							var level1 = tree.categories[i];
							if (level1.id == categoryId) {
								return [level1];
							}

							for (var ii = 0; ii < level1.subCategories.length; ii++) {
								var level2 = level1.subCategories[ii];
								if (level2.id == categoryId) {
									return [level2, level1];
								}

								for (var iii = 0; iii < level2.subCategories.length; iii++) {
									var level3 = level2.subCategories[iii];
									if (level3.id == categoryId) {
										return [level3, level2, level1];
									}
								}
							}
						}
					});
				}

				function getCategoryActualTypeByCategoryId(categoryId) {
					return getCategory(categoryId).then(function(categories) {
						var actualType;
						for (var i = 0; i < (categories || []).length; i++) {
							if (!actualType && categories[i].type && categories[i].type !== CATEGORIES_TYPES.INHERIT) {
								actualType = categories[i].type;
							}
						}
						return actualType || CATEGORIES_TYPES.REGULAR;
					});
				}

				function getCategoryActualTypeByProductData(product) {
					if (!(product && product.family && product.family.categories && product.family.categories[2])) {
						return $q.resolve(CATEGORIES_TYPES.REGULAR);
					}

					return getCategoryActualTypeByCategoryId(product.family.categories[2].id);
				}

				var retailerConfiguration;
				retailer.getRetailerSettings().then(function (configuration) {
					retailerConfiguration = configuration;
				});

				function removeCookie(name) {
					// This function will attempt to remove a cookie from all paths.
					var pathBits = location.pathname.split('/'),
						pathCurrent = ' path=';

					// do a simple pathless delete first.
					document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

					for (var i = 0; i < pathBits.length; i++) {
						pathCurrent += ((pathCurrent.substr(-1) != '/') ? '/' : '') + pathBits[i];
						document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
					}
				}

				function clearAllStorage(timeoutInMilliseconds) {
					timeoutInMilliseconds = timeoutInMilliseconds || 1000;
					$timeout(function () {
						clearLocalStorage();
						removeCookie('retailerId');

						ExternalCheckout.isInExternalCheckoutFinishPage().then(function(is) {
							if (!is) {
								$state.go('app.home');
							}
							reload();
						});
					}, timeoutInMilliseconds);
				}

				function clearLocalStorage() {
					// preserve the externalPaymentKey,
					// it should only be cleared when the user has seen finish message (external-checkout-finish.html)
					var externalCheckoutData = ExternalCheckout.getStorage();

					localStorage.clear();

					// restore the checkout key after clearing
					ExternalCheckout.setStorage(externalCheckoutData);
				}

				function validateMinimumCost(minimumOrderPrice, notIncludeSpecials, areaName) {
					cart = cart || $injector.get('Cart');
					var text = $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true' ? '<br/>' + _translateFilter('not_includes_delivery_fee') : '';

					return cart.validateMinimumCost(minimumOrderPrice, notIncludeSpecials)
						.catch(function (price) {
							closeUpdateDialog().then(function () {
								mDesign.alert(
									$interpolate(_translateFilter('Your order total {{areaName}}does not meet the {{minimum | currency}} minimum order total'))({
										areaName: areaName ? _translateFilter('to ') + areaName + ' ' : '',
										minimum: minimumOrderPrice
									}) + text + '.<br/>' +
									$interpolate(_translateFilter('Please add to your cart items worth {{difference | currency}} to proceed to checkout'))({
										difference: minimumOrderPrice - price
									}) + '.' +
									(notIncludeSpecials ? '<br/>' + _translateFilter('Not including items on sale') + '.' : '')
								);
							})
							return $q.reject();
						});

				}

				function _deleteLoyaltyClub(loyaltyClubId) {
					return api.request({
						method: 'DELETE',
						url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/' + loyaltyClubId
					}).then(function () {
						return User.getUserSettings(true);
					}).then(function() {
						// recalculate cart after removing the loyalty club
						var cartLines = cart.getLines();
						angular.forEach(cartLines, function (line) {
							cart.quantityChanged(line);
						});
					});
				}

				function _renewLoyaltyClub(userLoyalty, retailerLoyaltyClubDriver) {
					cart = cart || $injector.get('Cart');
					return cart.addLine({
						product: {
							id: retailerLoyaltyClubDriver.clientConfig.product.renewRetailerProductId
						},
						quantity: 1,
						type: CART_LINE_TYPES.REGISTER_LOYALTY,
						metaData: JSON.stringify({
							club: userLoyalty.loyaltyClubId,
							renewData : { customerId: userLoyalty.loyaltyCardId, retailerLoyaltyClubDriverId: retailerLoyaltyClubDriver.id }
						})
					}).then(function (){
						return mDesign.alert('{{\'Your membership has been renewed successfully\' | translate}}.<br/>' +
							'{{\'You can enjoy from the club benefits in this order\' | translate}}.')
							.then(function () {
								return User.getUserSettings(true);
						});
					});
				}

				function validateLoyaltyExpirationDate() {
					return $q.all({
						userSettings: User.getUserSettings(),
						retailerSettings: retailer.getRetailerSettings()
					}).then(function (data) {
						var promises = [];
						if (!data.userSettings.loyaltyClubs || !data.userSettings.loyaltyClubs.length) {
							return ;
						}

						angular.forEach(data.userSettings.loyaltyClubs, function (loyaltyClub) {
							var loyaltyClubDriver = (data.retailerSettings.loyaltyClubDrivers || []).find(function (driver) {
								return driver.loyaltyClubDriverId === loyaltyClub.loyaltyClubDriverId;
							});

							if (!loyaltyClub.loyaltyClubExpiration || new Date(loyaltyClub.loyaltyClubExpiration) >= new Date() ||
								loyaltyClubDriver.clientConfig.extendedLoyaltyClub) {
								return;
							}

							cart = cart || $injector.get('Cart');
							var loyaltyClientConfig = loyaltyClubDriver && loyaltyClubDriver.clientConfig;
							var renewProductId = loyaltyClientConfig && loyaltyClientConfig.product && loyaltyClientConfig.product.renewRetailerProductId;
							var renewProductPrice = 0;
							if (renewProductId) {
								var registerLoyaltyLine = cart.lines[renewProductId + '0'];
								if (registerLoyaltyLine)
									return;
								renewProductPrice = loyaltyClientConfig.product.renewProductPrice;
							}

							promises.push( _openLoyaltyMembershipExpiredDialog(data, loyaltyClub, loyaltyClubDriver, renewProductPrice));
						});

						return $q.all(promises);
					});
				}

				function _openLoyaltyMembershipExpiredDialog(data, userLoyalty, loyaltyClubDriver, renewProductPrice) {
					return mDesign.dialog({
						templateUrl: 'views/templates/loyalty-membership-expired.html',
						controllerAs: 'loyaltyWarningCtrl',
						controller: ['$scope', function () {
							var loyaltyWarningCtrl = this;

							loyaltyWarningCtrl.hide = mDesign.hide;
							loyaltyWarningCtrl.userData = data.userSettings;
							loyaltyWarningCtrl.userLoyalty = userLoyalty;
							loyaltyWarningCtrl.loyaltyClubDriver = loyaltyClubDriver;
							loyaltyWarningCtrl.isRenewActive = loyaltyClubDriver.clientConfig.isRenewActive;
							loyaltyWarningCtrl.isRegisterToClubObligation = loyaltyClubDriver.clientConfig.isRegisterToClubObligation;
							loyaltyWarningCtrl.renewProductPrice = renewProductPrice;
							loyaltyWarningCtrl.dateFormat = (data.retailerSettings.isUs ? 'MM/dd/yyyy' : 'dd/MM/yyyy');
						}]
					}).then(function (action) {
						if (action == 'deleteLoyalty') {
							return _deleteLoyaltyClub(userLoyalty.id).then(function() {
								//return back to cart
								return $q.reject(true);
							});
						}
						if (action == 'findCustomer') {
							return _deleteLoyaltyClub(userLoyalty.id).then(function() {
								$state.go('app.loyaltyClub');
								return $q.reject(true);
							});
						}
						if (action == 'renew') {
							return _renewLoyaltyClub(userLoyalty, loyaltyClubDriver);
						}

					}).catch(function (err) {
						if (err) {
							return $q.reject(err);
						}
						$window.history.back();
						return $q.reject();
					});
				}

				function _isInvalidProductSpecial(special) {
					var nowDate = new Date(),
						convertedEndDate = _getLocalDateFromUTCDate(new Date(special.endDate)),
						convertedStartDate = _getLocalDateFromUTCDate(new Date(special.startDate));

					return /*is coupon and coupon specials shouldn't be shown*/ (special.isCoupon && !Config.showCouponsSpecials) /*special dates are not active*/ || convertedStartDate >= nowDate || convertedEndDate <= nowDate;
				}

				//this converts server static UTC time - to local time on machine/browser
				function _getLocalDateFromUTCDate(date) {
					return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
				}

				function filterProductSpecials(productBranch) {
					var productSpecials = [];
					if (productBranch && productBranch.specials) {
						angular.forEach(productBranch.specials || [], function (special) {
							if (_isInvalidProductSpecial(special)) {
								return;
							}

							productSpecials.push(special);
						});
					}
					return productSpecials;
				}

				function productHasClubSpecial(productBranch) {
					if (!productBranch || !productBranch.specials) {
						return false;
					}

					var special;
					for (var i = 0; i < productBranch.specials.length; i++) {
						special = productBranch.specials[i];
						if (!special.loyaltyClubsIds || !special.loyaltyClubsIds.length || _isInvalidProductSpecial(special)) continue;

						return true;
					}

					return false;
				}

				function getCashbackLabel(label, total) {
					var rx = /\$\{(.*)\}/g;
					var arr = rx.exec(label);
					if (!arr || !arr.length) {
						return label;
					}
					var resultNumber = _currencyFilter(Number(Number(arr[1]) * Number(total)));
					return label.replace('${' + arr[1] + '}', resultNumber);
				}

				function goToProductDialog(product, catalogProductId, /*to support backward compatibility*/productId, closeProductPageFromOrder, itemsData) {
					if (product && product.oosProduct) {
						mDesign.hide();
					}

					// Handle source of product
					if (itemsData && itemsData.source) {
						var sourceName = extractHomeCarouselName(itemsData.source, itemsData.componentType, itemsData.componentPosition);
						itemsData.source = sourceName;
					}
					var params = {};
					if (!product && !catalogProductId && !productId) {
						if (closeProductPageFromOrder) {
							$rootScope.$emit('closeProductPage',{});
						}
						return SpDialogUrlManager.backClose();
					} else if (product) {
						product.itemsData = itemsData;
						SpDialogUrlManager.saveData('catalogProduct', product);
						params.catalogProduct = product.productId;
					} else if (catalogProductId) {
						params.catalogProduct = catalogProductId;
					}
					//to support backward compatibility
					else if (productId) {
						params.product = productId;
					}
					$rootScope.$emit('productPage',product.id);
					return SpDialogUrlManager.setDialogParams(params);
				}

				function extractHomeCarouselName(source, componentType, componentPosition) {
					var HOME_PAGE_CAROUSEL = 'home-page-carousel';

					if (!source) {
							return 'Not Set Yet';
					}

					if (source === HOME_PAGE_CAROUSEL && Number.isFinite(componentType) && Number.isFinite(componentPosition)) {
							var carouselType = +componentType,
									carouselPos = +componentPosition,
									carouselName = "";
							switch (carouselType) {
									case SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL:
											carouselName = 'large-products';
											break;
									case SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL:
											carouselName = 'normal-products';
											break;
									case SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL:
											carouselName = 'specials-products';
											break;
									case SP_HOME_PAGE_COMPONENT_TYPES.LINKS_CAROUSEL:
											carouselName = 'links-products';
											break;
									default:
											break;
							}

							return source + ' ' + carouselName + ' ' + carouselPos;
					} else {
							return source;
					}
				}
				
				/**
				 * Add the login dialog params to open it
				 * @public
				 *
				 * @param {string} [title]
				 * @param {string} [subTitle]
				 * @param {string} [retState]
				 * @param {string} [retStateParams]
				 * @param {boolean} [userConfirmationLogin]
				 *
				 * @returns {Promise}
				 */
				function goToLoginDialog(title, subTitle, retState, retStateParams, userConfirmationLogin) {
					if (Config.retailer.externalLoginSettings && Config.retailer.externalLoginSettings.isActive) {
						$rootScope.iframeIsReady = false;
						SpDialogUrlManager.backClose().then(function() {
							$timeout(function() {
								return $rootScope.ExternalRegistrationIFrameDialog.show(Config.retailer.externalLoginSettings.registrationIframeURL, {retailerId:Config.retailer.id});
							}, 0);
						});
						$timeout(function() {
							if (!$rootScope.iframeIsReady) {
								sendFailureNotification();
								return mDesign.alert(_translateFilter('iframe source not loaded'));
							}
						}, 5000);
					} else {
						return SpDialogUrlManager.setDialogParams({
							loginOrRegister: 1,
							loginDialogTitle: title || null,
							loginDialogSubTitle: subTitle || null,
							loginDialogRetState: retState || null,
							loginDialogRetStateParams: retStateParams || null,
							userConfirmationLogin: userConfirmationLogin
						});
					}
				}

				function sendFailureNotification() {
					$rootScope.iframeIsReady = true;
					return api.request({
						method: 'GET',
						url: '/v2/retailers/:rid/global/iframe-failure'
					}).then(function (res) {
					});
				}

				/**
				 * Open user migration dialog
				 * @public
				 *
				 * @param {string} [email]
				 *
				 * @returns {Promise}
				 */
				function goToUserMigrationDialog(email) {
					return SpDialogUrlManager.backClose().then(function() {
						$timeout(function() {
							return $rootScope.UserMigrationDialog.show(email);
						}, 0);
					});
				}

				/**
				 * Add the login dialog params to open it
				 * @public
				 *
				 * @param {number} recipeId
				 *
				 * @returns {Promise}
				 */
				function goToRecipeDialog(recipeId) {
					return SpDialogUrlManager.setDialogParams({
						recipe: recipeId
					});
				}

				//the score allows us to know which status is more important
				//that way we will be able to know what is the current state of the order even is one of its aisle statuses is more important
				var ORDER_STATUSES_SCORES = {};
				_setStatusesScore();

				function _setStatusesScore() {
					var _currentScore = 0;

					_currentScore = _setStatusesGroupScore(ORDER_STATUS_STAGES.RECEIVED, _currentScore);
					_currentScore = _setStatusesGroupScore(ORDER_STATUS_STAGES.IN_PROCESS, _currentScore);
					_currentScore = _setStatusesGroupScore(ORDER_STATUS_STAGES.READY, _currentScore);
					_currentScore = _setStatusesGroupScore(ORDER_STATUS_STAGES.FINISH, _currentScore);
					_currentScore = _setStatusesGroupScore(ORDER_STATUS_STAGES.CANCELLED, _currentScore);
				}

				function _setStatusesGroupScore(statusesGroup, initScore) {
					angular.forEach(statusesGroup, function(status, statusId) {
						ORDER_STATUSES_SCORES[statusId] = initScore++;
					});
					return initScore;
				}

				function isOrderInStatuses(order, statusesMap) {
					if (!order) {
						return false;
					}

					var orderStatus = order.statusId;
					if (order.aisleStatuses && order.aisleStatuses.length) {
						for (var i = 0; i < order.aisleStatuses.length; i++) {
							if (ORDER_STATUSES_SCORES[orderStatus] < ORDER_STATUSES_SCORES[order.aisleStatuses[i].status]) {
								orderStatus = order.aisleStatuses[i].status;
							}
						}
					}

					return !!statusesMap[orderStatus];
				}

				function getField(value, fields, callback) {
					for (var exist = 0; exist < fields.length; exist++) {
						if (!value[fields[exist]]) {
							return;
						}
						value = value[fields[exist]];
					}

					return callback ? callback(value) : value;
				}

				function getBranchSettings(configurations) {
					var settings = Object.assign({}, configurations.settings);

					angular.forEach(configurations.branches, function(branch) {
						if (branch.settings && branch.id === Config.branch.id) {
							Object.assign(settings, branch.settings);
						}
					});

					return settings;
				}

				function copyToClipboard(txt, event) {
					if (event) {
						event.stopPropagation();
					}

					var input = document.createElement('input');
					input.id = 'copy';
					input.value = txt;
					document.body.appendChild(input);
					input.select();
					document.execCommand('copy');
					document.body.removeChild(input);
				}

				/**
				 * Simulate post message for cordova in app browsers
				 * @public
				 *
				 * @param {object} inAppBrowserRef
				 */
				function listenForInAppMessage(inAppBrowserRef) {

					if ($window.cordova && $window.cordova.InAppBrowser) {
						inAppBrowserRef.addEventListener('loadstop', function() {
							//in app browser that will try post message need to save the message data in window.sp.message
							inAppBrowserRef.executeScript({ code: 'sp.message' }, function(values) {
								//once got a message data close the in app browser and post message
								$window.postMessage(values[0], '*');
								inAppBrowserRef.close();
							});
						});
					}
				}

				function waitForInitDialog() {
					if ($rootScope.intiDialogClosed) {
						return $q.resolve();
					}

					var defer = $q.defer(),
						listener = $rootScope.$on('initDialog.closed', function() {
							listener();
							defer.resolve();
						});
					return defer.promise;
				}

				function currentScopeListener(scope, listener) {
					scope.$on('$destroy', function () {
						listener();
					});
				}

				/**
				 * Replace Meta Title and Canonical link when product popup opened/closed
				 * @public
				 *
				 * @param {object} product
				 */
				function updateMetaByProduct(product) {
					if (product && product.productId && $rootScope.metaTags && Config.retailer) {
						//== Save previous Meta Data when opening product popup
						_previousMetaTag.currentTitle = $rootScope.metaTags.currentTitle;
						_previousMetaTag.productCanonical = $rootScope.metaTags.productCanonical;

						var productNames = (_nameFilter(product.names) || {});
						$rootScope.metaTags.currentTitle = productNames.short + ' | ' + (Config.retailer.metaTitle || Config.retailer.title);
						$rootScope.metaTags.productCanonical = Config.retailer.domain ? 'https://' + Config.retailer.domain + '?catalogProduct=' + product.productId : '';

						// set extra info
						if (product.family) {
							$rootScope.metaTags.familyName = (_nameFilter(product.family.names) || {}).name || null;
							if (product.family.categoriesPaths && product.family.categoriesPaths[0]) {
								angular.forEach(product.family.categoriesPaths[0], function (category, key) {
									var catName = ('subCategory'+ (key + 1));
									$rootScope.metaTags[catName] = (_nameFilter(category.names) || null);
								});
				
							}
						}

					} else if (_previousMetaTag.currentTitle) {
						//== Restoring saved Meta Data when closing product popup
						$rootScope.metaTags.currentTitle = _previousMetaTag.currentTitle;
						$rootScope.metaTags.productCanonical= _previousMetaTag.productCanonical;
					} else {
						//== Set default Meta Data
						$rootScope.metaTags.currentTitle = Config.retailer.metaTitle || Config.retailer.title;
						$rootScope.metaTags.productCanonical = '';
					}
				}

				function SingleOperationQueue() {
					var self = this,
						_waitingOperation,
						_activeOperation;

					self.do = doAction;

					function doAction(action) {
						if (_waitingOperation) {
							return _waitingOperation;
						}

						if (_activeOperation) {
							return _waitingOperation = _activeOperation.then(function() {
								_waitingOperation = undefined;
								return doAction(action);
							});
						}

						var promise = $q.resolve(action());
						_activeOperation = promise.then(_resetActive, _resetActive);
						return promise;
					}

					function _resetActive() {
						_activeOperation = undefined;
					}
				}

				function loyaltyClubAutoRenew(userData) {
					var loyaltyClubDriver = Config.retailer.loyaltyClubDriver;
					if (!userData || !userData.loyaltyClubs || !userData.loyaltyClubs.length || !Config.retailer.loyaltyClubDrivers || !Config.retailer.loyaltyClubDrivers.length) {
						return;
					}

					api.request({
						method: 'POST',
						url: '/v2/retailers/:rid/users/:uid//loyalty-clubs/_auto-renew'
					}).then(function (response) {
						if (response.isDeleted) {
							var driverClientConfig = loyaltyClubDriver.clientConfig;
							return $state.go(driverClientConfig && driverClientConfig.extendedLoyaltyClub ? 'app.extendedLoyaltyClub' : 'app.loyaltyClub');
						}
						if (!response.isChanged) {
							return;
						}

						User.getUserSettings(true);
					});
				}

				/**
				 * @param {User} userData 
				 * @returns {Promise<{isExpired: true>}}
				 */
				function syncExpiredLoyaltyUser(userData) {
          var isUserJoinClub = !!(
            userData &&
            userData.loyaltyClubs &&
            userData.loyaltyClubs.length
          );

          var curDriver =
            Config &&
            Config.retailer &&
            Config.retailer.loyaltyClubDrivers &&
            Config.retailer.loyaltyClubDrivers.length &&
            Config.retailer.loyaltyClubDrivers[0];

          // ECOM-14090 currently check expire only available on limited providers
          var isValidDriver =
            curDriver &&
            curDriver.isActive === true &&
            curDriver.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.NCR_MULTIPASS_CASHBACK;

          if (!isUserJoinClub || !isValidDriver) {
						return Promise.resolve({ isExpired: false });
          }

          return api
            .request({
              method: "PATCH",
              url: "/v2/retailers/:rid/users/:uid/loyalty-clubs/_sync-expired",
            })
            .then(function (result) {
              return result;
            })
            .catch(function () {
              // still return data to prevent crash flow
              return { isExpired: false };
            });
        }

				function loyaltyClubAutoConnect() {
					var driver = (Config.retailer.loyaltyClubDrivers || []).find(function (driver) {
						return driver.isActive && driver.clientConfig.isAutoConnectActive;
					});

					if (!driver) {
						return;
					}

					api.request({
						method: 'POST',
						url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/_auto-connect'
					});
				}


				/**
				 * Open loyalty registration iframe dialog
				 * @public
				 *
				 * @param {string} [url]
				 * @param {Object} [userSettings]
				 *
				 * @returns {Promise}
				 */
				function goToLoyaltyIFrameDialog(url, userSettings) {
					var isUserConsentRequired = localStorage.getItem('isFirstUserLogin') === 'false' || !localStorage.getItem('isFirstUserLogin'),
						termsAgreement = !isUserConsentRequired,
						promoAgreement = isUserConsentRequired ? false : userSettings.allowSendPromotions;

					var data = {
						retailerId: Config.retailer.id,
						sessionId: User.getUserLoginData().token,
						firstName: userSettings.firstName,
						lastName: userSettings.lastName,
						email: userSettings.email,
						termsAgreement: termsAgreement,
						promoAgreement: promoAgreement,
						isUserConsentRequired: isUserConsentRequired
					}

					return SpDialogUrlManager.backClose().then(function() {
						$timeout(function() {
							return $rootScope.LoyaltyIFrameDialog.show(url, data);
						}, 1000);
					});
				}


				/**
				 * Display message when there are no available delivery slots at all
				 * @public
				 *
				 * @param {Boolean} isForce
				 */
				function showDeliveryNoAvailableSlotsPopup(isForce) {
					var data = _getDeliveryNoAvailableSlotsMessage(isForce);

					if(!data) {
						return $q.resolve();
					} else {
						if(!isForce) $rootScope.deliveryNoAvailableSlotsShown = true;

						return mDesign.dialog({
							templateUrl: 'views/templates/delivery-no-slots-popup.html',
							disableClosing: true,
							showClose: true,
							controller: ['$scope', function ($scope) {
								$scope.title = data.title;
								$scope.text = (data.text || '').replace(/\n/g, '<br />');
								$scope.cancel = function () {
									mDesign.hide();
								};
							}]
						});
					}
				}

				function _getDeliveryNoAvailableSlotsMessage(isForce) {
					var currentLanguageId = Config.language.id,
						defaultLanguageId = Config.retailer.languageId,
						popupEnabled = Config.retailer.settings.shippingNoAvailableSlotsPopupEnable,
						messagesArr = Config.retailer.shippingNoAvailableSlotsMessages,
						messagesObj = {},
						existsLanguageId = null;

					//== Disabled in backend or displayed already for this site refresh
					if(!popupEnabled) {
						return null;
					}

					//== Disabled in backend or displayed already for this site refresh
					if($rootScope.deliveryNoAvailableSlotsShown && !isForce) {
						return null;
					}

					//== Enabled in backend but got no messages
					if(!(messagesArr && Array.isArray(messagesArr))) {
						return null;
					}

					//== Convert object received from API to Associated Array (Object) by language ID
					angular.forEach(messagesArr, function(message) {
						if(message.languageId) {
							existsLanguageId = message.languageId;
							messagesObj[message.languageId] = {
								title: message.title,
								text: message.text
							}
						}
					});

					//== check if message exists on current selected language or on Retailer's default language
					if(messagesObj[currentLanguageId]) {
						return messagesObj[currentLanguageId];
					} else if (messagesObj[defaultLanguageId]) {
						return messagesObj[defaultLanguageId];
					}

					//== return the existing message or null
					return existsLanguageId && messagesObj[existsLanguageId] || null;
				}

				function reload() {
					DeepLinkHandler.setReloadState();
					$window.location.reload();
				}

				function isIos() {
					return /iPhone|iPad|iPod|Macintosh/i.test($window.navigator.userAgent);
				}

				function showCouponReminderDialog(coupon) {
					mDesign.dialog({
						focusOnOpen: false,
						clickOutsideToClose: true,
						templateUrl: 'views/templates/coupon-reminder-dialog.html',
						controller: ['$scope', function ($scope) {
							var couponReminderCtrl = this;
							couponReminderCtrl.coupon = coupon;
							couponReminderCtrl.hideDialog = mDesign.hide;

							cart = cart || $injector.get('Cart');
							couponReminderCtrl.isInCart = !!Object.values(cart.lines).find(function (line) {
								return line.product && line.product.id === coupon.id;
							})
						}],
						controllerAs: 'couponReminderCtrl',
						styleClass: 'coupon-reminder'
					});
				}

				function compileUserAddress(addressDetails) {
					if (User.settings.addresses && User.settings.addresses.length && typeof(User.settings.addresses[0]) !== 'undefined') {
						var userAddress = addressDetails || User.settings.addresses[0];
						return ((userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '') +
							(userAddress.city ? ' ' + userAddress.city : '') +
							(userAddress.country ? ' ' + userAddress.country : '')).trim();
					}
				}

				function isSingleItemSpecial(special) {
					return !!special.item && !special.levels[0].gifts.every(function(gifts) {
						return special.levels[0].purchases.every(function(purchases) {
							return gifts.products && purchases.products && JSON.stringify(gifts.products) !== JSON.stringify(purchases.products);
						});
					});
				}

				function isShowOriginalPriceEnabled() {
					return Config.retailer 
					&& Config.retailer.settings 
					&& Config.retailer.settings.isNewPromotionDesignEnabled === 'true' 
					&& Config.retailer.settings.isShowOriginalPrice === 'true';
				}

				function getCountries() {
					if(Countries && Countries.countries) {
						return Object.entries(Countries.countries).map(function(row) {
							row[1].isoCode = row[0];
							return row[1];
						});
					}
					return [];
				}
				function getCountryAutocompleteOptions() {
					var countries = getCountries();
					var ukCountryNames = ['England', 'Scotland', 'Wales', 'Northern Ireland'];
					if(!countries || !countries.length) {
						return [];
					}
					var countryNames = countries.map(function (country) {
						return country.name;
					});
					countryNames = countryNames.concat(ukCountryNames);
					return countryNames.sort();
				}
				function checkAddressFields(address) {
					return ['city', 'text1', 'zipCode', 'country'].some(function (item) {
						return !!address[item]
					})
				}

				function getAddressFromAutocomplete(result) {
					if(!result || !result.address_components || !result.address_components.length) {
						return {};
					}
					var address = {};
					result.address_components.forEach(function (item) {
						if(item.types && item.types.length) {
							item.types.forEach(function (area) {
								switch (area) {
									case GOOGLE_ADDRESS_TYPES.COUNTRY:
										address.country = item.long_name
										break;
									case GOOGLE_ADDRESS_TYPES.LOCALITY:
										address.city = item.long_name
										break;
									case GOOGLE_ADDRESS_TYPES.POSTAL_TOWN:
										address.city = item.long_name
										break;
									case GOOGLE_ADDRESS_TYPES.POSTAL_CODE:
										address.zipCode = item.long_name
										break;
									case GOOGLE_ADDRESS_TYPES.ROUTE:
										address.text1 = item.long_name
										address.text2 = item.long_name
										break;
								}
							})
						}
					});
					if (!address.country && config.retailer.settings.defaultShippingCountry) {
						address.country = config.retailer.settings.defaultShippingCountry;
					}
					return address;
				}

				function getAddressByZipCode(zipCode) {
					return api.request({
						method: 'GET',
						url: '/v2/zipcode-providers/:rid/address',
						params: {
							zipCode: zipCode,
							languageId: Config.retailer.languageId
						}
					}).then(function (results) {
						if(!results) {
							return [];
						}

						angular.forEach(results.results, function (address) {
							var fixedAddress = address.street + ', ' + address.city + ' ' + zipCode + ', ' + address.country;
							address.text1 = address.street;

							if(address.buildingNumber) {
								fixedAddress = address.buildingNumber + ', ' + fixedAddress;
								address.text1 = address.buildingNumber + ' ' + address.text1;
							}
							if(address.buildingName) {
								fixedAddress = address.buildingName + ', ' + fixedAddress;
								address.text1 = address.buildingName + ', ' + address.text1;
							}
							if(address.subBuildingName) {
								fixedAddress = address.subBuildingName + ', ' + fixedAddress;
								address.text1 = address.subBuildingName + ', ' + address.text1;
							}

							address.description = fixedAddress;
							address.houseNumber = address.buildingNumber;
						});

						return results.results;
					})
				}

				function getCountryCode(countryName) {
					if (countryName) {
						var countries = getCountries();
						var country = countries.find(function (cnt) {
							return cnt.name.toLowerCase() === countryName.toLowerCase();
						})
						return country && country.isoCode ? country.isoCode : null;
					}
					return null;
				}

				function checkForDeliveryPickupServiceFee(lines) {
					return Config.waitForInit().then(function () {
						var area = Config.getBranchArea();
						var linesArr = lines && typeof lines === 'object' ? Object.values(lines) : lines && Array.isArray(lines) ? lines : [];
						return linesArr.some(function (line) {
							return line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY || line.type == SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE
						}) && area && !!(area.retailerBranchProductDeliveryPrice || area.retailerProductDeliveryPrice)
					})
				}

				function isEmptyObjectWithDelivery (data) {
					if (data) {
						var keys = Object.keys(data);
						if (keys.length === 1) {
							return data[keys[0]].type === SP_SERVICES.CART_LINE_TYPES.DELIVERY;
						}
						return keys.length === 0;
					}
					return false;
				}

				function convertArrayToObject(list, fieldName) {
					var arrayMap = {};
					list.forEach(function (row) {
						arrayMap[row[fieldName]] = row;
					});

					return arrayMap;
				}


				function cleanUserAddressObject(userAddress) {
					delete userAddress.buildingName;
					delete userAddress.buildingNumber;
					delete userAddress.subBuildingName;

					var redundantFields = ['geoCoordinates', 'isSuggestedAddress', 'hasHouseNumberAndRoute', 'isShowEditAddress', 'placeId', 'isDisabledAddressField'];
					redundantFields.forEach(function (field) {
						delete userAddress[field];
					});
				}

				function getUserCashbackPoints(loyaltyClubId, pointsDecimalRound) {
					return api.request({
						method: 'GET',
						url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/' + loyaltyClubId + '/_points',
					}).then(function (response) {
						response.points = Number.isInteger(response.points) ? response.points : response.points.toFixed(pointsDecimalRound);
						return response;
					});
				}

				function showBottleDepositDisclaimer (evt, productId) {
					evt.stopPropagation();
					var elementId = 'bottle_disclaimer_' + productId

					closePopup(elementId);
					var el = document.getElementById(elementId);
					if (el) {
						angular.element(el).hasClass('active') ? angular.element(el).removeClass('active') : angular.element(el).addClass('active');
					}
				}

				function closePopup(elementId) {
					var elements = document.getElementsByClassName('bottle-deposit-disclaimer');
					if (elements && elements.length) {
						elements = Array.from(elements);
						elements.forEach(function (el) {
							if(el.getAttribute('id') != elementId ) {
								angular.element(el).removeClass('active')
							}
						})
					}
				}

				function setGlobalCouponRedemptions(coupons) {
					if( !(coupons && Array.isArray(coupons) && coupons.length) || $rootScope.couponRedemptionsInProgress) {
						return;
					}

					$rootScope.couponRedemptionsInProgress = true;
					var redemptions = {};
					coupons.forEach(function(coupon) {
						if(coupon.remainingredemptions && coupon.remainingredemptions > 1 && coupon.special && coupon.id) {
							redemptions[coupon.id] = coupon.remainingredemptions; // This parameter we get from Birdzi for each coupon
						}
					});

					$rootScope.couponRedemptions = redemptions;
					$rootScope.couponRedemptionsInProgress = false;
				}

				function checkPopupDisabledUrls(currentRoute, disabledUrlsText) {
					if (currentRoute === '\/' || !disabledUrlsText) {
						return false;
					}
					var tempPath = currentRoute.slice(0, -1);
					return disabledUrlsText.trim().includes(tempPath);
				}

				//Accessibility live alerts
				function setLiveAlert(text, replacement, withoutTimeout) {
					var $oldAlertElement = angular.element(document.querySelector('.accessibility-alert'));
					if (!!$oldAlertElement) {
						$oldAlertElement.remove();
					}

					var $alertElement = angular.element(document.createElement('div'))
						.addClass('accessibility-alert sr-only-element');

					$alertElement[0].setAttribute('aria-live', 'assertive');
					$alertElement[0].setAttribute('role', 'alert');
					$alertElement[0].setAttribute('aria-relevant', 'additions');

					$alertElement.text(_translateFilter(text).replace('X', replacement));

					if (withoutTimeout) {
						document.body.appendChild($alertElement[0]);
						return;
					}

					$timeout(function () {
						document.body.appendChild($alertElement[0]);
					});

				}

				function setProductIndexPosition(products) {
					var i = 1;
					angular.forEach(products, function(product) {
						product.indexPosition = i;
						i++;
					});
				}

				/**
				 * Format address based on country code
				 * @param {string} countryCode
				 * @param {string} route
				 * @param {string} street
				 * @returns {string}
				 */
				function _formatAddressByCountryCode(countryCode, houseNumber, route) {
					var SPAIN_COUNTRY_CODE = 'ES';
					var RUSSIA_COUNTRY_CODE = 'RU';
					var ISRAEL_COUNTRY_CODE = 'IL';
					switch(countryCode) {
					case SPAIN_COUNTRY_CODE:
					case RUSSIA_COUNTRY_CODE: {
						/**
						 * In these countries, The address often has the format: [Route], [Street Number]
						 * For example: RouteTest, 123
						*/
						return [route, houseNumber].join(', ').trim();
					}
					case ISRAEL_COUNTRY_CODE: {
						/**
						 * In this country, The address often has the format: [Route] [Street Number]
						 * For example: RouteTest 123
						*/
						return [route, houseNumber].join(' ').trim();
					}
					default: {
						/**
						 * The common address format in the word often has following format: [Street Number] [Route]
						 * For example: 123 RouteTest
						*/
						return [houseNumber, route].join(' ').trim();
					}
					}
				}

				/**
				 * @param {string} countryCode
				 * @param {string} houseNumber
				 * @param {string} route
				 * @returns {{value: string, hasHouseNumberAndRoute:boolean}}
				 */
				function constructAdrsText1(countryCode, houseNumber, route, city) {
					var text1Obj = {
						value:'',
						hasHouseNumberAndRoute: true,
					};
					if (city) {
						var replaceText1 = houseNumber || route || city;
						var hasHouseNumberAndRoute = houseNumber && route;
						text1Obj.hasHouseNumberAndRoute = hasHouseNumberAndRoute;
						text1Obj.value = hasHouseNumberAndRoute ? _formatAddressByCountryCode(countryCode, houseNumber, route) : replaceText1;
					}
					return text1Obj;
				}

				function _isCashbackLoyaltyClub(loyaltyClubId) {
					var isCashbackLoyaltyClub = false;
					angular.forEach(Config.retailer.loyaltyClubDrivers, function (loyaltyClubDriver) {
					  isCashbackLoyaltyClub =
						loyaltyClubDriver.clientConfig.loyaltyClubs &&
						loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId] &&
						loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId].pointsToCashback;
					});
					return isCashbackLoyaltyClub;
				}

				function findUserCashbackLoyaltyClub() {
					return (
						User.settings.loyaltyClubs &&
						User.settings.loyaltyClubs.length &&
						User.settings.loyaltyClubs.find(function (club) {
							return _isCashbackLoyaltyClub(club.loyaltyClubId);
						})
					);
				}

				/**
				 * ECOM-7371
				 * @param {number} num
				 * @returns {number | null}
				 */
				function roundNumber(num){
					if (isNaN(num)) {
					  return null;
					}

					return Math.round(num * 100) / 100;
				}

				/**
				* @returns {boolean}
				*/
				function checkIsPremiumEditOrderToggled() {
					if (
						!Config.retailer ||
						!Config.retailer ||
						!Config.retailer.premiumFeaturesEnabled ||
						!Config.retailer.settings
					) {
						return false;
					}

          var PREMIUM_UPDATE_TIME_SLOT_ID = 25;
          var isEnablePremium = Config.retailer.premiumFeaturesEnabled.includes(
            PREMIUM_UPDATE_TIME_SLOT_ID
          );

					var settings = Config.retailer.settings;
					var isToggled = settings.changeTimeSlot && settings.changeTimeSlot.isActive;

					return isEnablePremium && isToggled;
				}

				/**
				 * Display as UTC timezone so don't need to convert
				 * @param {Date | string} shippingTimeFrom // saved as UTC timezone but it's actually retailer timezone
				 * @returns {Date | null} // UTC timezone
				 */
				function calculateLastUpdateOrderDateTime(shippingTimeFrom) {
          if (!shippingTimeFrom || !checkIsPremiumEditOrderToggled()) {
            return null;
          }

          var canUpdateBeforeHours = +Config.retailer.settings.changeTimeSlot.canUpdateBeforeHours;

          if (isNaN(canUpdateBeforeHours)) {
            return null;
          }

          var shippingTimeFromMs = new Date(shippingTimeFrom).getTime();
          var configBeforeMs = canUpdateBeforeHours * 60 * 60 * 1000;

          var lastUpdateDateTime = new Date(shippingTimeFromMs - configBeforeMs);

          return lastUpdateDateTime;
        }

				/**
				* @param {number} deliveryTypeId
				* @returns {'' | 'delivery' | 'pickup'}
				*/
				function getDeliveryTypeText(deliveryTypeId) {
					switch (deliveryTypeId) {
						case SP_SERVICES.DELIVERY_TYPES.DELIVERY:
						case SP_SERVICES.DELIVERY_TYPES.EXPRESS_DELIVERY:
							return 'delivery'

						case SP_SERVICES.DELIVERY_TYPES.PICKUP:
						case SP_SERVICES.DELIVERY_TYPES.PICK_AND_GO:
						case SP_SERVICES.DELIVERY_TYPES.SCAN_AND_GO:
							return 'pickup'

						default:
							return ''
					}
				}

				/**
				 * Check logic to enable button update edit timeslot. Assume that we already check can update order items previously when open popup
				 * @param {Order} order Refer type Order at launch-edit-order.js
				 * @returns
				 */
				function checkCanUpdateTimeSlot(order) {
					if (!checkIsPremiumEditOrderToggled()) {
						return false;
					}

					var lastUpdateDateTime = calculateLastUpdateOrderDateTime(order.shippingTimeFrom);

					if (!lastUpdateDateTime) {
						return false;
					}

					var curTime = new Date()

					// RETAILER TIMEZONE = USER TIMEZONE
					// lastUpdateDateTime is at UTC time. Need to convert to retailer timezone
					var lastUpdateTimeInRetailerTimezone = _addOffsetToNormalizedTime(lastUpdateDateTime);
					var isBeforeLastUpdateTime = curTime < lastUpdateTimeInRetailerTimezone;

					var isNotCollected = isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED);

					var isRegularDeliveryTimeSlot =
						order.deliveryTimeTypeId === DELIVERY_TIMES_TYPES.REGULAR;

					var isBeforePostponeUpdateTime = curTime < calculatePostponeUpdateDateTime(order.timePlaced);

					return isBeforeLastUpdateTime && isNotCollected && isRegularDeliveryTimeSlot && isBeforePostponeUpdateTime;
				}

				/**
			 * @param {*} orderTimePlaced 
			 * @return {Date | null}
			 */
				function calculatePostponeUpdateDateTime(orderTimePlaced) {
          var MINUTE_IN_MS = 1000 * 60;
          var DAY_IN_MS = MINUTE_IN_MS * 60 * 24;

          var maxPostponeDays =
            (Config.retailer.settings.changeTimeSlot &&
              Config.retailer.settings.changeTimeSlot.maxPostponeDays) ||
            1;

          var postponeUpdateDateTime = new Date(
            new Date(orderTimePlaced).getTime() + DAY_IN_MS * maxPostponeDays
          );

          return postponeUpdateDateTime;
        }

				/**
				* @param {TabName} step
				* @param {boolean} isCancelOnClose
				* @param {number} orderId
				* @returns
				*/
				function openEditOrderDialog(stepName, isCancelOnClose, orderId) {
					if (!orderId) {
						throw new Error('Missing orderId');
					}

					if (typeof isCancelOnClose !== 'boolean') {
						throw new Error('Missing isCancelOnClose');
					}

          return SpDialogUrlManager.setDialogParams({
            updateOrderV2: "1",
            step: stepName,
            isCancelOnClose: isCancelOnClose ? isCancelOnClose : false,
            orderId: orderId,
          });
        }

				/**
				 * @return {boolena}
				 */
				function checkIsCalculateCheckoutTime(){
					return [
						CHARGE_SPECIALS_CALCULATION_TIME.CHECKOUT_POS,
						CHARGE_SPECIALS_CALCULATION_TIME.CHECKOUT_SP,
					].includes(Config.retailer.settings.specialsCalculationTime);
				}

				/**
				 * @param {Record<LanguageEnum, string>} text
				 * @param {string} defTxtKey
				 * @param { {key: string, value: string}[] =} keyValParams
				 * @return {string}
				 */
				function getTextByLangAndReplaceParams(text, defTxtKey, keyValParams) {
					text = text || {}

					var extractedText =
						angular.copy(text[$rootScope.config.language.culture]) ||
						_translateFilter(defTxtKey);

					var replacedText = extractedText;

					if (keyValParams) {
						for (var i = 0; i < keyValParams.length; i++) {
							replacedText = replacedText.replace(keyValParams[i].key, keyValParams[i].value);
						}
					}

					return replacedText;
				}

				/**
				 * @description Retrieves new time slot data from local storage.
				 * @param {number} orderId
				 * @param {number} cartId
				 * @returns new time slot data
				 */
				function getNewTimeSlotData(orderId, cartId) {
					var localStorageTimeslotDataId = 'updateTimeSlot-orderId-' + orderId + '-cartId-' + cartId;
					var timeSlotData = JSON.parse(localStorage.getItem(localStorageTimeslotDataId));

					return timeSlotData;
				}

				/**
				 * @param {*} dateTime
				 * @returns
				 */
				function getTranslatedFullDateTime(dateTime){
					var dateTimeFormat = $rootScope.config.isUs ? "MM/dd hh:mm a" : "dd/MM HH:mm";
					var dayOfWeek = dateFilter(dateTime, "EEEE", "UTC");
					var transDayOfWeek = _translateFilter(dayOfWeek);

					var transTimeCalculate = dateFilter(dateTime, dateTimeFormat, "UTC");

					return transDayOfWeek + ", " + transTimeCalculate;
				}

                function showPaymentErrorDialog(error) {
                    mDesign.dialog({
                        clickOutsideToClose: true,
                        templateUrl: 'views/templates/payment-error-dialog.html',
                        controller: ['$scope', function ($scope) {
                            $scope.errorObj = error;
                            $scope.cancel = cancel;

                            function cancel() {
                                mDesign.hide();
                            }
                        }]
                    });
                }

				function closeUpdateDialog(delayTime) {
					var isUpdateDialogOpenning = !!document.querySelector('md-dialog.edit-order-v2');
					if(!isUpdateDialogOpenning){
						return $q.resolve();
					}
					var p = $q.defer();
					$rootScope.$emit('order.update.timeslot.close');
	
					delayTime = delayTime || 1000;
					$timeout(function () {
						p.resolve();
					}, delayTime);
					return p.promise;
				}

				function isInternalUrl(url) {
					try {
						if (url.startsWith('/')) {
							url = location.protocol + '//' + location.host + url;
						}
						return new URL(url).origin == location.origin;
					} catch (error) {
						return false;
					}
				}

                function isRetailerPromotionActive() {
                    if (!Config.retailer.promotion) {
                        return false
                    }

                    if (Config.retailer.promotion.eventType === $rootScope.RETAILER_PROMOTION_EVENTS.REGISTER && !!User.getUserLoginData()) {
                        return false;
                    }

                    if (!!User.getUserLoginData() && User.settings) {
                        var promotion = (User.settings.eligiblePromotions || []).find(function (promotionId) {
                            return Config.retailer.promotion.id === promotionId;
                        });

                        return !!promotion;
                    }

                    return true;
                }

				function getRetailerSupportedDeliveryMethod(){
					var result = {
						isPickupSupported: false,
						isDeliverySupported: false
					};
	
					var retailerBranches = Config.retailer.branches;
					angular.forEach(retailerBranches, function (branch) {
						if(!branch.isDisabled) {
							angular.forEach(branch.areas, function (area) {
								if (area.deliveryTypeId === SP_SERVICES.DELIVERY_TYPES.PICKUP) {
									result.isPickupSupported = true;
								}
		
								if (area.deliveryTypeId === SP_SERVICES.DELIVERY_TYPES.DELIVERY) {
									result.isDeliverySupported = true;
								}
		
								if (result.isPickupSupported && result.isDeliverySupported) {
									return result;
								}
							});
						}
					});
	
					return result;
				}
			}
		])
		.run(['$rootScope', 'Util', function ($rootScope, util) {
			$rootScope.util = util;
		}]);
})(angular);
