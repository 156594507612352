(function (angular) {
    "use strict";
    angular.module('mobilezuz').directive('spSpecialsList', ['Retailer',
        function (retailer) {
            return {
                restrict: 'EA',
                templateUrl: 'views/templates/single-product-content.html',
                scope: {
                    vProduct:"=vProduct",
                    vOffer: "=vOffer"
                },
                controllerAs: 'specialListItemCtrl',
                controller: function ($scope, Util) {
                    var specialListItemCtrl = this;
                    var _listeners =[];
                    retailer.getRetailerSettings().then(function (res) {
                        specialListItemCtrl.showSaleExperations = res.showSpecialsValidity;
                    });

                    _listeners.push($scope.$watch('vProduct', function(){
                        if(!$scope.vProduct){
                            return;
                        }
                        $scope.product = $scope.vProduct;
                    }));
                    _listeners.push($scope.$watch('vOffer', function(){
                        if(!$scope.vOffer){
                            return;
                        }
                        $scope.offer = $scope.vOffer;
                    }));
                    Util.destroyListeners($scope, _listeners);

                },
                link: function ($scope, element, attrs) {
                    $scope.isSpecialPage = attrs.isSpecialPage === 'true';
                }
            };
        }]);
})(angular);
